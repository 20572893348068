import ImageWithFallback from 'ui/components/ImageWithFallback/ImageWithFallback';
import LayeredImage from 'ui/components/LayeredImage/LayeredImage';
import { cltw } from 'ui';
import Image from 'next/image';
import { getFirstImageForVariant } from 'ui/utils/variantHelpers';

export default function PhoneAndSelectedDevice({
  setSrc,
  src,
  deviceImage,
  isJfHeroCarouselEnabled,
  wywImages,
}: {
  setSrc: React.Dispatch<React.SetStateAction<string>>;
  src: string;
  deviceImage: string;
  isJfHeroCarouselEnabled: boolean;
  wywImages?: string[];
}) {
  return (
    <>
      <ImageWithFallback
        alt='WHOOP app'
        className={cltw(
          'absolute bottom-[3%] right-[32%] h-auto w-[50%] md:w-[52%] md:max-w-[270px] lg:w-[53%] lg:max-w-[340px]',
          !isJfHeroCarouselEnabled ? 'md:w-[58%] md:max-w-[370px]' : '',
        )}
        fallback='/recovery/en/recovery-overview.webp'
        height={672}
        priority
        setSrc={setSrc}
        src={src}
        width={370}
      />
      {wywImages ? (
        <div className='absolute bottom-[6%] right-[10%] h-auto w-[46%]'>
          <LayeredImage images={wywImages} />
        </div>
      ) : (
        <>
          <Image
            alt='WHOOP device'
            className={cltw(
              'absolute bottom-[6%] right-[9%] h-auto w-[46%]',
              deviceImage === '/wyw_flash.gif' ? '' : 'hidden',
            )}
            height={1}
            priority
            src='/wyw_flash.gif'
            width={1}
          />
          <Image
            alt='WHOOP device'
            className={cltw(
              'absolute bottom-[6%] right-[12%] h-auto w-[40%]',
              deviceImage !== '/wyw_flash.gif' ? '' : 'hidden',
            )}
            height={1}
            priority
            src={deviceImage}
            width={1}
          />
        </>
      )}
    </>
  );
}
