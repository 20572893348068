import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import { i18n } from '@whoop/i18n/lang/client';
import type {
  ApplicationScheme,
  JoinFlowContent,
} from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import {
  getFirstVariant,
  getMembershipTypeApplicationSchemeForCurrency,
  getPromoDisclaimer,
  getReferrer,
} from 'ui';
import type { Product } from 'services/generated/commerce-service';
import { bandMaterialSorter } from 'ui/utils/variantHelpers';
import { ONYX_SKU } from 'ui/utils/fixtures/helperFixtures';
import { ANNUAL, TRIAL, TWO_YEAR } from '@/lib/constants/memberships';
import { formatDisplayPrice } from 'lib/utils/formatHelpers';

export const getLandingPageSubtextCopy = ({
  promoContent,
  promoInfo,
  currency,
  hasTrialMembership,
  language,
  region,
  isRafGiftFlow = false,
  skipMemSelection = '',
}: {
  promoContent?: JoinFlowContent;
  promoInfo?: CheckoutPromo;
  currency: Currency;
  hasTrialMembership: boolean;
  language: Language;
  region: Region;
  isRafGiftFlow?: boolean;
  skipMemSelection?: string;
}): {
  mobileSubtext: string;
  desktopSubtext: string;
  imageSrc?: string;
} => {
  if (skipMemSelection) {
    let membershipText = '';
    if (skipMemSelection === ANNUAL) {
      membershipText = i18n.t('joinLandingPage:annualMembership');
    } else if (skipMemSelection === TWO_YEAR) {
      membershipText = i18n.t('joinLandingPage:24MonthMembership');
    } else if (skipMemSelection === TRIAL) {
      membershipText = i18n.t('joinLandingPage:priceToBegin', {
        priceToBegin: formatDisplayPrice(0, {
          currency,
          language,
          region,
        }),
      });
    }
    const subtext = `${membershipText} | ${i18n.t(
      'joinLandingPage:whoop4Included',
    )}`;
    return {
      imageSrc: undefined,
      desktopSubtext: subtext,
      mobileSubtext: subtext,
    };
  }

  // If partnerships/rafGift promo content has been populated, use that.
  // Eventually, that will contain the default text and RAF/Affiliate text as well
  if (promoContent && (promoContent.partnership_name || isRafGiftFlow)) {
    return {
      imageSrc: promoContent.content.image,
      desktopSubtext: promoContent.content.base_desktop_subtext,
      mobileSubtext: promoContent.content.base_mobile_subtext,
    };
  }

  const referrer = getReferrer(promoInfo);
  if (referrer && promoInfo) {
    const validPromo = getMembershipTypeApplicationSchemeForCurrency(
      promoInfo.promo_code.application_schemes as ApplicationScheme[],
      currency,
    );
    const referrerText = getPromoDisclaimer({
      promo: validPromo,
      location: 'primary',
      referrer,
      currency,
      language,
      region,
    });
    return {
      imageSrc: referrer.avatar_url,
      desktopSubtext: referrerText,
      mobileSubtext: referrerText,
    };
  }

  const desktopSubtext = hasTrialMembership
    ? i18n.t('joinLandingPage:joinWhoopForAsLowAs', {
        priceToBegin: formatDisplayPrice(0, { currency, language, region }),
      })
    : i18n.t('joinLandingPage:joinWhoopNoTrial');

  const priceToBegin = hasTrialMembership
    ? `${i18n.t('joinLandingPage:priceToBegin', {
        priceToBegin: `${formatDisplayPrice(0, {
          currency,
          language,
          region,
        })}`,
      })} | `
    : '';

  return {
    imageSrc: undefined,
    desktopSubtext,
    mobileSubtext: `${priceToBegin} ${i18n.t(
      'joinLandingPage:whoop4Included',
    )}`,
  };
};

export const sortAndFilterLandingSwatches = (
  products: Product[],
): Product[] => {
  return products
    .filter(
      (product) =>
        product.product_type === 'wrist-band' ||
        product.product_type === 'whoop-your-way',
    )
    .sort((product1, product2) => {
      const variant1 = getFirstVariant(product1);
      const variant2 = getFirstVariant(product2);
      // Sort the Onyx band (default product) first
      if (variant1.sku === ONYX_SKU) return -1;
      if (variant2.sku === ONYX_SKU) return 1;
      return bandMaterialSorter(variant1, variant2);
    });
};
