'use client';
import React from 'react';
import Image from 'next/image';

interface LandingPageSubtext extends React.HTMLAttributes<HTMLDivElement> {
  imageSrc?: string;
  desktopSubtext: string;
  mobileSubtext: string | React.ReactNode;
}

export default function LandingPageSubtext({
  imageSrc,
  desktopSubtext,
  mobileSubtext,
}: LandingPageSubtext): React.JSX.Element | null {
  return (
    <div className='flex flex-row items-center justify-center pb-7 text-left md:justify-start md:pb-14'>
      {imageSrc ? (
        <Image
          alt='profile'
          className='mr-3 h-10 w-10 rounded-full border-[3px] border-opacity-10'
          height={40}
          src={imageSrc}
          width={40}
        />
      ) : null}
      <p className='hidden self-center text-2xl font-light md:block'>
        {desktopSubtext}
      </p>
      <p
        className={`text-md my-0 mr-3 self-center font-light md:hidden ${
          imageSrc ? '' : 'text-center'
        }`}
      >
        {mobileSubtext}
      </p>
    </div>
  );
}
