'use client';

import React, { useMemo } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useShallow } from 'zustand/react/shallow';
import type { Variant } from 'services/generated/commerce-service';
import { getPriceValues } from 'ui/utils/variantHelpers';
import { MONTHLY_MEMBERSHIP_KEY } from 'ui/tests/fixtures/ecommProductFixtures';
import { getMembershipVariantV2 } from 'ui';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useFormatter } from '@/hooks/formatter';

const headerClassNames =
  'mx-0 text-4xl font-semibold tracking-tighter sm:text-5xl md:pb-3 lg:text-6xl';

export default function LandingPageHeader(): React.JSX.Element {
  const {
    language,
    ecommCatalog,
    promoInfo,
    siteWidePromoContent,
    isRafGiftFlow,
    isSkipMemSelectFlow,
  } = useSiteStore(
    useShallow((state) => ({
      language: state.language,
      ecommCatalog: state.ecommCatalog,
      promoInfo: state.promoInfo,
      siteWidePromoContent: state.siteWidePromoContent,
      isRafGiftFlow: state.isRafGiftFlow,
      isSkipMemSelectFlow: state.isSkipMemSelectFlow,
    })),
  );

  const { formatDisplayPrice } = useFormatter();
  const { t } = useTranslation('joinLandingPage', language);

  const monthlyMembership: Variant | undefined = useMemo(
    () =>
      getMembershipVariantV2(MONTHLY_MEMBERSHIP_KEY, ecommCatalog.memberships),
    [ecommCatalog],
  );

  if (isRafGiftFlow) {
    return <h2 className={headerClassNames}>{t('orderPage:giftWhoop')}</h2>;
  }

  if (isSkipMemSelectFlow) {
    return <h2 className={headerClassNames}>{t('chooseABand')}</h2>;
  }

  return (
    <h2 className={headerClassNames}>
      {(promoInfo?.referral || promoInfo?.affiliate) &&
      monthlyMembership &&
      !siteWidePromoContent ? (
        <>
          <div className='hidden text-4xl md:block'>
            <span>{t('joinWhoopFor')}</span>
            <span className='line-through opacity-20'>
              {formatDisplayPrice(
                getPriceValues(monthlyMembership).displayPrice,
              )}
            </span>
            <span>{` ${formatDisplayPrice(0)}`}</span>
          </div>
          <div className='block text-4xl md:hidden'>
            <span>{`${t('joinWhoopFor')} ${formatDisplayPrice(0)}`}</span>
          </div>
        </>
      ) : (promoInfo?.referral || promoInfo?.affiliate) &&
        !siteWidePromoContent ? (
        <span>{`${t('joinWhoopFor')} ${formatDisplayPrice(0)}`}</span>
      ) : (
        t('joinWhoop')
      )}
    </h2>
  );
}
