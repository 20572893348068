'use client';

import { useTranslation } from '@whoop/i18n/lang/client';
import { UnstyledButton } from 'ui/components/ButtonV2/Button';
import { useMemo } from 'react';
import type { ParsedCartItem } from 'ui/types/cartTypes';
import {
  ENGRAVING_SKU,
  TRIAL_MEMBERSHIP_SKU,
} from 'ui/tests/fixtures/ecommProductFixtures';

interface EngravingSectionProps {
  showEngraving: () => void;
  cartItems: ParsedCartItem[];
  engravingPrice: string;
}

export default function EngravingSection({
  showEngraving,
  cartItems,
  engravingPrice,
}: EngravingSectionProps): JSX.Element | null {
  const { t } = useTranslation('joinLandingPage');

  const { canAdd, hasAddedEngraving } = useMemo(() => {
    const trialInCart = cartItems.some(
      (item) => item.sku === TRIAL_MEMBERSHIP_SKU,
    );
    const isEngravingItemInCart = cartItems.some(
      (item) => item.sku === ENGRAVING_SKU,
    );
    return {
      canAdd: !trialInCart,
      hasAddedEngraving: isEngravingItemInCart,
    };
  }, [cartItems]);

  if (!canAdd) return <></>;

  return (
    <UnstyledButton
      className='sm:p-sm flex w-full cursor-pointer items-center border-none bg-transparent p-2 hover:opacity-70 md:p-4'
      onClick={showEngraving}
      type='button'
    >
      <div>
        <p className='text-accent text-body-xs mb-0 mt-0 inline-block pr-3 tracking-widest underline'>
          {t(hasAddedEngraving ? 'editEngraving' : 'addEngraving')}
        </p>
        <div className='inline-block'>
          <p className='text-xs text-black md:font-medium'>{engravingPrice}</p>
        </div>
      </div>
    </UnstyledButton>
  );
}
