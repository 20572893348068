'use client';

import { useState, useMemo } from 'react';
import {
  cltw,
  BAND_KEY,
  CLASP_KEY,
  FAST_LINK_KEY,
  getDefaultVariantsFromProducts,
  getFirstVariant,
  hasMembershipKey,
  HOOK_KEY,
  createWYWImagesObject,
} from 'ui';
import { Button } from 'ui/components/ButtonV2/Button';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from '@whoop/i18n/lang/client';
import dynamic from 'next/dynamic';
import type {
  CatalogResponse,
  Product,
  Variant,
} from 'services/generated/commerce-service';
import type { JoinFlowContent } from 'services/generated/growth-content-service';
import type { SelectedVariantMap } from 'ui/utils/variantHelpers';
import {
  getFirstImageForVariant,
  getPriceValues,
  getVariantColor,
  isWywVariant,
} from 'ui/utils/variantHelpers';
import { useToast } from 'ui/components/Toast/use-toast';
import { getEngravingError, transformToBEModel } from 'ui/utils/engravingUtils';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { TRIAL_MEMBERSHIP_KEY } from 'ui/tests/fixtures/ecommProductFixtures';
import { useSiteStore } from '@/store/SiteStoreProvider';
import LandingPageHeader from '@/components/landingPage/LandingPageHeader/LandingPageHeader';
import {
  addEngravingToEcommCart,
  addToEcommCart,
  addWYWToCart,
} from '@/actions/cartActions';
import RadioSwatchesV2 from '@/components/RadioSwatchesV2/RadioSwatchesV2';
import { useNavigation } from '@/hooks/navigation';
import { useFormatter } from '@/hooks/formatter';
import LandingPageBottomCTA from '@/components/landingPage/LandingPageBottomCTA/LandingPageBottomCTA';
import { useAnalytics } from '@/hooks/analytics';
import {
  getItemProperties,
  getMembershipProperties,
  getNameFromVariant,
} from '@/lib/utils/analyticsHelper';
import { env } from 'env';
import { getMembershipFromQueryParam } from '@/lib/utils/skipMembershipSelectHelper';
import { featureFlagNames } from '@/client/featureFlagClient';
import HeroCarousel from '@/components/HeroCarousel/HeroCarousel';
import PhoneAndSelectedDevice from '@/components/landingPage/LandingPageContent/PhoneAndSelectedDevice';
import EngravingSection from '../EngravingSection/EngravingSection';
import LandingPageSubtext from '../landingPageSubtext/landingPageSubtext';
import Eyebrow from '../Eyebrow/Eyebrow';
import {
  getLandingPageSubtextCopy,
  sortAndFilterLandingSwatches,
} from './landingPageContentUtils';

const Gen4Data = dynamic(() => import('../gen4Data/gen4Data'), { ssr: false });

const HowItWorks = dynamic(() => import('../howItWorks/howItWorks'), {
  ssr: false,
});

const WhoopAppData = dynamic(() => import('../whoopAppData/whoopAppData'), {
  ssr: false,
});

const CoachingData = dynamic(() => import('../coachingData/coachingData'), {
  ssr: false,
});

const AddToCartModalV2 = dynamic(
  () => import('../../accessoriesV2/AddToCartModal/AddToCartModalV2'),
  {
    ssr: false,
  },
);

const WhoopYourWayProductDetails = dynamic(
  () =>
    import(
      '../../accessoriesV2/WhoopYourWayProductDetails/WhoopYourWayProductDetails'
    ),
  {
    ssr: false,
  },
);

const NormalProductDetails = dynamic(
  () => import('../../accessoriesV2/NormalProductDetails/NormalProductDetails'),
  {
    ssr: false,
  },
);

const Cart = dynamic(() => import('../../Cart/Cart'), {
  ssr: false,
});

interface LandingPageContentProps {
  isMobile?: boolean;
  ecommCatalog: CatalogResponse;
  promoContent?: JoinFlowContent;
  skipMemSelection?: string;
}
export default function LandingPageContent({
  isMobile,
  ecommCatalog,
  promoContent: propPromoContent,
  skipMemSelection,
}: LandingPageContentProps): React.JSX.Element {
  const { goTo } = useNavigation();
  const { formatDisplayPrice } = useFormatter();
  const {
    currency,
    language,
    region,
    country,
    storePromoContent,
    promoInfo,
    isRafGiftFlow,
    cart,
    setEcommCart,
    engravingInfo,
    setEngravingInfo,
    setAsAGift,
    setIsCartOpen,
    featureFlags,
  } = useSiteStore(
    useShallow((state) => ({
      currency: state.currency,
      language: state.language,
      region: state.region,
      country: state.country,
      storePromoContent: state.promoContent,
      promoInfo: state.promoInfo,
      isRafGiftFlow: state.isRafGiftFlow,
      cart: state.cart,
      setEcommCart: state.setEcommCart,
      engravingInfo: state.engravingInfo,
      setEngravingInfo: state.setEngravingInfo,
      setAsAGift: state.setAsAGift,
      setIsCartOpen: state.setIsCartOpen,
      featureFlags: state.featureFlags,
    })),
  );

  const israelDisabled = featureFlags[featureFlagNames.DISABLE_IL_JF];
  const inIsraelAndDisabled = region === 'IL' && israelDisabled;

  const { trackAmplitudeEvent } = useAnalytics();
  const { promoContent } = useMemo(() => {
    const promoContent = storePromoContent
      ? storePromoContent
      : propPromoContent;
    return {
      promoContent,
    };
  }, [storePromoContent, propPromoContent]);

  const { t } = useTranslation('joinLandingPage', language);
  const { t: cartT } = useTranslation('cart', language);
  const [hoveredVariant, setHoveredVariant] = useState<Variant | undefined>(
    undefined,
  );
  const engravingProduct: Product | null =
    ecommCatalog.initial_products.find(
      (product) => product.product_type === 'engraving',
    ) ?? null;
  const disableEngraving =
    !engravingInfo?.data || Boolean(getEngravingError(engravingInfo));

  const swatchProducts = useMemo(() => {
    return sortAndFilterLandingSwatches(ecommCatalog.initial_products);
  }, ecommCatalog.initial_products);

  const [selectedVariant, setSelectedVariant] = useState<Variant>(
    getFirstVariant(swatchProducts[0]),
  );

  const isJfHeroCarouselEnabled = Boolean(isMobile && !promoInfo?.affiliate);

  const [showEngravingModal, setShowEngravingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const wywParentCategory =
    ecommCatalog.accessories.find(
      (category) => category.key === 'whoop-your-way-kit',
    ) ?? null;
  const wywVariant = wywParentCategory?.products[0]
    ? getFirstVariant(wywParentCategory.products[0])
    : null;
  const [showWywModal, setShowWywModal] = useState(false);
  const [selectedWywVariants, setSelectedWywVariants] =
    useState<SelectedVariantMap>();

  const [src, setSrc] = useState(
    `/recovery/${language}/recovery-overview.webp`,
  );

  const deviceImage = useMemo(() => {
    const variant = hoveredVariant ?? selectedVariant;
    if (isWywVariant(variant)) {
      return '/wyw_flash.gif';
    }

    return getFirstImageForVariant(variant);
  }, [hoveredVariant, selectedVariant]);

  const wywImagesObject = useMemo(() => {
    if (wywVariant && selectedWywVariants) {
      return createWYWImagesObject(wywVariant, selectedWywVariants).front;
    }
  }, [wywVariant, selectedWywVariants]);

  const wywImages = useMemo(() => {
    const variant = hoveredVariant ?? selectedVariant;
    if (variant && isWywVariant(variant)) {
      return wywImagesObject;
    }
  }, [hoveredVariant, selectedVariant, wywImagesObject]);

  const { toast } = useToast();

  const onSwatchHover = (variant: Variant | undefined): void => {
    setHoveredVariant(variant);
  };
  const onSwatchClick = (variant: Variant) => {
    if (isWywVariant(variant)) {
      setShowWywModal(true);
    } else {
      setSelectedWywVariants(undefined);
    }
    setSelectedVariant(variant);
  };

  const getLabel = () => {
    const swatchToRender = hoveredVariant ?? selectedVariant;
    if (isWywVariant(swatchToRender)) {
      return (
        <p className='text-sm font-bold uppercase tracking-wider'>
          <span className='font-semibold'>+ </span>
          {t('whoopYourWay')}
        </p>
      );
    }
    const { displayPrice, originalPrice, isDiscounted, percentSavings } =
      getPriceValues(swatchToRender);
    const formattedDisplayPrice = formatDisplayPrice(displayPrice, {
      roundUp: true,
    });
    const formattedOriginalPrice = formatDisplayPrice(originalPrice, {
      roundUp: true,
    });
    const isDefaultProduct =
      swatchToRender === getFirstVariant(swatchProducts[0]);

    return (
      <>
        <p className='pr-2 text-sm font-bold uppercase tracking-wider'>
          {isDefaultProduct ? null : <span className='font-semibold'>+ </span>}
          {getVariantColor(swatchToRender)}
        </p>
        <p className='pr-2 text-sm uppercase tracking-wide'>
          {isDefaultProduct ? t('included') : formattedDisplayPrice}
        </p>
        <span className='text-gray-b50 pr-2 text-sm uppercase tracking-wide line-through'>
          {!isDefaultProduct && isDiscounted ? formattedOriginalPrice : null}
        </span>
        {isDiscounted && percentSavings ? (
          <p className='text-blue-strain whitespace-nowrap text-sm font-semibold uppercase tracking-wide'>
            {t('savePercent', { percent: percentSavings })}
          </p>
        ) : null}
      </>
    );
  };

  const hasTrialMembership = hasMembershipKey(
    TRIAL_MEMBERSHIP_KEY,
    ecommCatalog.memberships,
  );

  const landingPageSubtextCopy = getLandingPageSubtextCopy({
    hasTrialMembership,
    promoContent,
    promoInfo,
    currency,
    language,
    region,
    isRafGiftFlow,
    skipMemSelection,
  });

  const handleContinue = async (): Promise<void> => {
    const productName = getNameFromVariant(
      ecommCatalog.initial_products,
      selectedVariant,
    );
    trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
      ...getItemProperties(productName, selectedVariant),
      action: 'click',
    });

    try {
      const newCart = await addToEcommCart({
        requestBody: {
          cart_id: cart.id,
          country,
          locale: language,
          sku: selectedVariant.sku,
          quantity: 1,
        },
      });
      setEcommCart(newCart);
      trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
        ...getItemProperties(productName, selectedVariant),
        action: 'success',
      });
      goTo('membership');
    } catch (error) {
      setIsLoading(false);
      toast({
        title: cartT('errorTitle'),
        description: cartT('errorDescription'),
        variant: 'error',
      });
    }
  };

  const handleJoinNow = async (): Promise<void> => {
    setIsLoading(true);
    setAsAGift(false);
    await handleContinue();
  };

  const handleGift = async (): Promise<void> => {
    setIsLoading(true);
    setAsAGift(true);
    await handleContinue();
  };

  const handleAddEngraving = async (
    variant: Variant,
    quantity: number,
    setIsAddingToCart: (isAddingToCart: boolean) => void,
  ) => {
    if (engravingInfo) {
      setIsAddingToCart(true);
      trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
        ...getItemProperties(engravingProduct?.name || '', variant),
        action: 'click',
      });
      try {
        const newCart = await addEngravingToEcommCart({
          requestBody: {
            cart_id: cart.id,
            country,
            locale: language,
            sku: variant.sku,
            quantity,
            engraving_params: transformToBEModel(engravingInfo),
          },
        });
        setEcommCart(newCart);
        trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
          ...getItemProperties(engravingProduct?.name || '', variant),
          action: 'success',
        });
      } catch (error) {
        toast({
          title: cartT('errorTitle'),
          description: cartT('errorDescription'),
          variant: 'error',
        });
      } finally {
        setIsAddingToCart(false);
        setShowEngravingModal(false);
      }
    }
  };

  const handleAddWYW = async (
    variant: Variant,
    quantity: number,
    setIsAddingToCart: (isAddingToCart: boolean) => void,
  ) => {
    if (selectedWywVariants) {
      setIsAddingToCart(true);
      try {
        const newCart = await addWYWToCart({
          requestBody: {
            cart_id: cart.id,
            country,
            locale: language,
            wyw_sku: variant.sku,
            quantity,
            band_sku: selectedWywVariants[BAND_KEY].sku,
            clasp_sku: selectedWywVariants[CLASP_KEY].sku,
            hook_sku: selectedWywVariants[HOOK_KEY].sku,
            fast_link_sku: selectedWywVariants[FAST_LINK_KEY].sku,
          },
        });
        setEcommCart(newCart);
        const name = wywParentCategory?.products[0]?.name || '';
        trackAmplitudeEvent(
          AmplitudeEvents.AddedItemToCart,
          getItemProperties(name, variant),
        );
      } catch (error) {
        toast({
          title: cartT('errorTitle'),
          description: cartT('errorDescription'),
          variant: 'error',
        });
      } finally {
        setIsAddingToCart(false);
        setShowWywModal(false);
      }
    }
  };

  const checkSkipMembership = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const membership = getMembershipFromQueryParam(
        skipMemSelection ?? '',
        ecommCatalog.memberships,
      );
      if (skipMemSelection && membership) {
        const productName = getNameFromVariant(
          ecommCatalog.initial_products,
          selectedVariant,
        );
        trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
          ...getItemProperties(productName, selectedVariant),
          action: 'click',
        });
        setIsLoading(true);
        setAsAGift(false);
        // Adding the selected variant band variant to the cart if it exists
        let newCart = await addToEcommCart({
          requestBody: {
            cart_id: cart.id,
            country,
            locale: language,
            sku: selectedVariant.sku,
            quantity: 1,
          },
        });
        trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
          ...getItemProperties(productName, selectedVariant),
          action: 'success',
        });
        trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
          ...getMembershipProperties(membership.variants[0], language),
          action: 'click',
        });

        newCart = await addToEcommCart({
          requestBody: {
            cart_id: newCart.id,
            country,
            locale: language,
            sku: membership.variants[0].sku,
            quantity: 1,
          },
        });
        trackAmplitudeEvent(AmplitudeEvents.AddedItemToCart, {
          ...getMembershipProperties(membership.variants[0], language),
          action: 'success',
        });
        setEcommCart(newCart);
        setIsCartOpen(true);
        setIsLoading(false);
      } else {
        await handleJoinNow();
      }
    } catch (error) {
      toast({
        title: cartT('errorTitle'),
        description: cartT('errorDescription'),
        variant: 'error',
      });
    }
  };

  return (
    <>
      {skipMemSelection ? <Cart CTAButtonsVariant='preferCheckout' /> : null}
      {showEngravingModal && engravingProduct ? (
        <AddToCartModalV2
          handleAddToCart={handleAddEngraving}
          isDisabled={disableEngraving}
          isOpen={showEngravingModal}
          selectedVariant={getFirstVariant(engravingProduct)}
          setIsOpen={setShowEngravingModal}
        >
          <NormalProductDetails
            currency={currency}
            engravingInfo={engravingInfo}
            group={{
              category_id: engravingProduct.product_id,
              name: engravingProduct.name,
              key: null,
              products: [engravingProduct],
              categories: [],
              description: null,
            }}
            selectedVariant={getFirstVariant(engravingProduct)}
            setEngravingInfo={setEngravingInfo}
          />
        </AddToCartModalV2>
      ) : null}
      {wywParentCategory && wywVariant && showWywModal ? (
        <AddToCartModalV2
          handleAddToCart={handleAddWYW}
          isOpen={showWywModal}
          selectedVariant={wywVariant}
          setIsOpen={setShowWywModal}
        >
          <WhoopYourWayProductDetails
            closeModal={() => setShowWywModal(false)}
            country={country}
            language={language}
            parentCategory={wywParentCategory}
            selectedWywOptions={selectedWywVariants}
            setSelectedWywOptions={setSelectedWywVariants}
          />
        </AddToCartModalV2>
      ) : null}
      <section
        className={cltw(
          'grid h-full min-h-[850px] grid-cols-1 md:grid-cols-2 lg:grid-cols-2',
          isJfHeroCarouselEnabled ? 'bg-white' : 'bg-gray-50',
        )}
      >
        <div
          className={cltw(
            'hidden justify-end self-start pl-8 md:flex',
            isJfHeroCarouselEnabled ? 'pt-14' : 'pt-20',
          )}
        >
          <HeroCarousel
            hoveredVariant={hoveredVariant ?? selectedVariant}
            isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
            language={language}
            region={region}
            wywImages={wywImages}
          >
            <div className='relative aspect-square h-full w-full md:max-w-[700px]'>
              <PhoneAndSelectedDevice
                deviceImage={deviceImage}
                isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
                setSrc={setSrc}
                src={src}
                wywImages={wywImages}
              />
            </div>
          </HeroCarousel>
        </div>
        <div className='w-full flex-grow basis-0 px-4 py-5 md:py-12'>
          <div className='m-auto flex w-full max-w-[500px] flex-col justify-center text-center sm:pt-5 md:m-0 md:pl-16 md:text-left'>
            <LandingPageHeader />
            <LandingPageSubtext
              desktopSubtext={landingPageSubtextCopy.desktopSubtext}
              imageSrc={landingPageSubtextCopy.imageSrc}
              mobileSubtext={landingPageSubtextCopy.mobileSubtext}
            />
            <div className='flex items-center justify-center md:hidden md:px-0'>
              <HeroCarousel
                hoveredVariant={hoveredVariant ?? selectedVariant}
                isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
                isMobile
                language={language}
                region={region}
              >
                <div className='relative aspect-square h-full w-full'>
                  <PhoneAndSelectedDevice
                    deviceImage={deviceImage}
                    isJfHeroCarouselEnabled={isJfHeroCarouselEnabled}
                    setSrc={setSrc}
                    src={src}
                    wywImages={wywImages}
                  />
                </div>
              </HeroCarousel>
            </div>
            {/* Personalize section */}
            <div>
              <p className='experiment-tracking my-0 hidden pb-3 text-2xl font-semibold md:flex'>
                {t('chooseWhoop4Band')}
              </p>
              <p className='my-0 flex justify-center pt-3 text-lg font-semibold md:hidden md:text-2xl'>
                {t('chooseWhoop4Band')}
              </p>
              <div className='flex justify-center uppercase md:justify-start'>
                {getLabel()}
              </div>
              <Eyebrow />
            </div>
            <div className='z-20 flex flex-col gap-1 py-3 md:pt-6'>
              <RadioSwatchesV2
                allowDeselect
                defaultSelection={getFirstVariant(swatchProducts[0])}
                onClick={onSwatchClick}
                onHover={onSwatchHover}
                selectedSwatch={selectedVariant}
                showCheckmark
                swatchSize={isMobile ? 'medium' : 'large'}
                swatchType='color'
                variants={getDefaultVariantsFromProducts(swatchProducts)}
              />
            </div>
            {/* Engraving section */}
            {engravingProduct && !skipMemSelection && !inIsraelAndDisabled ? (
              <EngravingSection
                cartItems={cart.cartItems}
                engravingPrice={formatDisplayPrice(
                  getPriceValues(getFirstVariant(engravingProduct))
                    .displayPrice,
                )}
                showEngraving={() => setShowEngravingModal(true)}
              />
            ) : null}
            <div className='flex flex-col justify-center gap-5 pb-5 pt-3 sm:pb-5 sm:pt-5'>
              {/* CTA Button */}
              {isRafGiftFlow ? null : (
                <Button
                  className='w-full'
                  data-testid='join-now'
                  onClick={checkSkipMembership}
                  size='medium'
                  variant='secondary'
                  disabled={isLoading || inIsraelAndDisabled}
                >
                  {t('continue')}
                </Button>
              )}
              {skipMemSelection ? null : (
                <Button
                  className={`w-full ${isRafGiftFlow ? '' : 'bg-transparent'}`}
                  data-testid='buy-as-gift'
                  disabled={isLoading || inIsraelAndDisabled}
                  onClick={handleGift}
                  size='medium'
                  variant={isRafGiftFlow ? 'bright' : 'primary'}
                >
                  {t('buyAsGift')}
                </Button>
              )}
            </div>
            {/* Sign in section */}
            {!skipMemSelection && !inIsraelAndDisabled ? (
              <div className='flex w-full flex-row justify-center gap-3 pt-4'>
                <div className='text-sm tracking-wider'>
                  {t('alreadyAMember')}
                </div>
                <a
                  className='text-accent ml-1 border-none bg-none p-0 text-sm tracking-wider underline'
                  href={`${
                    env.NEXT_PUBLIC_UPGRADE_URL
                  }/${language}-${region.toLowerCase()}/upgrade`}
                  rel='noreferer noopener'
                  target='_blank'
                  type='button'
                >
                  {t('getYourWhoop4')}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <HowItWorks isJfHeroCarouselEnabled={isJfHeroCarouselEnabled} />
      <Gen4Data />
      <WhoopAppData />
      <CoachingData />
      {!inIsraelAndDisabled && (
        <LandingPageBottomCTA
          disabled={isLoading}
          onClick={checkSkipMembership}
          showTrialsDisclaimer={hasTrialMembership}
        />
      )}
    </>
  );
}
