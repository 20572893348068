'use client';

import React, { useEffect } from 'react';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { UUID_COOKIE_NAME } from 'whoop-analytics/lib/sources/experiment';
import { whoopAnalyticsScrollTracker } from 'whoop-analytics/lib/sources/whoop-analytics';
import { useShallow } from 'zustand/react/shallow';
import type { JoinFlowContent } from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import { setCookie } from 'whoop-analytics/utils';
import { persist } from '@whoop/web-auth-client';
import { deleteCookie } from 'cookies-next';
import type { CatalogResponse } from 'services/generated/commerce-service';
import type { ParsedCartDTO } from 'ui/types/cartTypes';
import { useStripe } from '@stripe/react-stripe-js';
import { getOneTouchDigitalWalletOptionsV2 } from 'ui/utils/paymentHelpers';
import { useAnalytics } from '@/hooks/analytics';
import { useSiteStore } from '@/store/SiteStoreProvider';
import type {
  IInitialSiteData,
  PromoType,
} from '@/lib/utils/initialDataFetcher';
import {
  JF_EXPRESS_CHECKOUT,
  JFRP_EX_NZ_AU,
} from '@/lib/constants/experiments';
import type { SiteWidePromoContent } from '@/types/siteWidePromoTypes';
import LandingPageContent from './LandingPageContent/LandingPageContent';

interface LandingPageProps {
  featureFlags?: Record<string, boolean>;
  isMobile?: boolean;
  initialData: IInitialSiteData;
  ecommCatalog: CatalogResponse;
  promoContent?: JoinFlowContent;
  promo?: CheckoutPromo;
  sitewidePromoContent?: SiteWidePromoContent;
  language?: string;
  experimentUUID?: string;
  authToken: string;
  promoType: PromoType;
  skipMemSelection?: string;
  discountedCart?: ParsedCartDTO;
}
export default function LandingPage({
  featureFlags,
  isMobile,
  initialData,
  ecommCatalog,
  promo,
  promoContent,
  promoType,
  sitewidePromoContent,
  experimentUUID,
  authToken,
  skipMemSelection,
  discountedCart,
}: LandingPageProps): JSX.Element {
  const {
    initializeFeatureFlags,
    initializePromo,
    setEcommCatalog,
    setReturnShippingCost,
    setIsRafGiftFlow,
    setPromoType,
    setEcommCart,
    setIsSkipMemSelectFlow,
    region,
    currency,
    setStripePaymentRequest,
    cart,
  } = useSiteStore(
    useShallow((state) => ({
      initializeFeatureFlags: state.initializeFeatureFlags,
      initializePromo: state.initializePromo,
      setEcommCatalog: state.setEcommCatalog,
      setReturnShippingCost: state.setReturnShippingCost,
      setIsRafGiftFlow: state.setIsRafGiftFlow,
      setPromoType: state.setPromoType,
      currency: state.currency,
      setEcommCart: state.setEcommCart,
      setIsSkipMemSelectFlow: state.setIsSkipMemSelectFlow,
      region: state.region,
      setStripePaymentRequest: state.setStripePaymentRequest,
      cart: state.cart,
    })),
  );
  const stripe = useStripe();
  const {
    initialize,
    isInitialized,
    trackPageView,
    trackAmplitudeEvent,
    setAmplitudeUserProperty,
  } = useAnalytics();

  const isAUNZ = ['AU', 'NZ'].includes(region);

  useEffect(() => {
    if (isInitialized) {
      trackAmplitudeEvent(AmplitudeEvents.ViewedJoinFlow, {
        skip_mem_select: skipMemSelection,
      });
      setAmplitudeUserProperty(JFRP_EX_NZ_AU, 'experiment');
      if (isAUNZ) {
        trackAmplitudeEvent(AmplitudeEvents.ViewedJoinFlowRollout);
      }
    }

    // Don't have trackAmplitudeEvent as a dependency because otherwise this useEffect runs more than it need to
  }, [isInitialized]);

  // Android and ios save the auth cookie slightly differently than our FE library (it is JSON parsed on the FE)
  // This unifies those approaches to make sure they all work throughout this flow
  const handleAuthDifferences = () => {
    // This deletes the mobile auth cookie so that we only have the web auth token saved
    deleteCookie('@whoopauth.accessToken');
    persist(authToken);
  };

  const initializeStripePaymentOptions = async () => {
    if (!stripe) return;

    const options = getOneTouchDigitalWalletOptionsV2({
      cart,
      currency,
      stripeCountry: '',
    });

    const request = stripe.paymentRequest({
      ...options,
      // This is for the region that our stripe account is setup in. Not the users country. Don't change this.
      country: 'US',
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: true,
    });

    const paymentRequestOptions = await request.canMakePayment();
    if (!paymentRequestOptions) {
      setAmplitudeUserProperty('express-checkout-available', 'false');
      setAmplitudeUserProperty(JF_EXPRESS_CHECKOUT, 'none');
      return;
    }

    setStripePaymentRequest({
      request,
      paymentOptionsAvailable: paymentRequestOptions,
    });
  };

  function init(): void {
    setEcommCatalog(ecommCatalog);
    if (discountedCart) {
      setEcommCart(discountedCart);
    }
    setReturnShippingCost(initialData.returnShippingCost);
    setPromoType(promoType);
    const isRafGift = Boolean(authToken);
    setIsRafGiftFlow(isRafGift);
    if (isRafGift) {
      handleAuthDifferences();
    }
    initializeFeatureFlags(featureFlags ?? {});
    initializePromo(promoContent, promo, sitewidePromoContent, isRafGift);

    initialize();
    setIsSkipMemSelectFlow(Boolean(skipMemSelection));
    trackPageView('Landing');
  }

  function initScroll(): void {
    document.addEventListener('scroll', () => {
      whoopAnalyticsScrollTracker(trackAmplitudeEvent);
    });
  }

  useEffect(() => {
    // If making a server side call to get experiment UUID, we need to set the cookie here
    // This way that same uuid is used for all experiment calls made throughout the app
    if (experimentUUID) {
      setCookie(UUID_COOKIE_NAME, experimentUUID);
    }
    init();
    initScroll();
    return () => {
      document.removeEventListener('scroll', () => {
        whoopAnalyticsScrollTracker(trackAmplitudeEvent);
      });
    };
  }, []);

  useEffect(() => {
    initializeStripePaymentOptions();
  }, [stripe]);

  return (
    <div className='bg-white'>
      <LandingPageContent
        ecommCatalog={ecommCatalog}
        isMobile={isMobile}
        promoContent={promoContent}
        skipMemSelection={skipMemSelection}
      />
    </div>
  );
}
