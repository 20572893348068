import { getMembershipProductV2 } from 'ui';
import type { Product } from 'services/generated/commerce-service';
import { TRIAL_MEMBERSHIP_KEY } from 'ui/tests/fixtures/ecommProductFixtures';
import { TRIAL } from '@/lib/constants/memberships';

// Add membership types here when we want to rollout for annual/24 month
export const membershipQueryParamOptions = [TRIAL];

export const getMembershipFromQueryParam = (
  membershipSearchParam: string,
  membershipsAvailable: Product[],
): Product | undefined => {
  // If proper skip mem select query param is present
  if (!membershipQueryParamOptions.includes(membershipSearchParam)) {
    return undefined;
  }

  // Validate that the membership is available
  // Example : ROW flows don’t have trials, so exclude them if they come from a trials membership link
  if (membershipSearchParam === TRIAL) {
    return getMembershipProductV2(TRIAL_MEMBERSHIP_KEY, membershipsAvailable);
  }
  return undefined;
};
